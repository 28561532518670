import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, InputLabel, Typography } from "@mui/material";
import TextInputComponent from "../../../components/TextInputComponent";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";

const EmailDialog=({openEmailModal,handleCloseEmailModal,handleSendEmail})=>{
    const [emailSubject, setEmailSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');
    useEffect(()=>{
        return ()=>{
            setEmailSubject('');
            setEmailBody('');
        }
    },[openEmailModal,handleCloseEmailModal,handleSendEmail])
    return (<Dialog
        open={openEmailModal}
        onClose={handleCloseEmailModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Send Email to Selected Users</DialogTitle>
        <Divider />
        <DialogContent sx={{ paddingBottom: "0" }}>
          <TextInputComponent
            name="subject"
            label="Subject"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            fullWidth
            required={true}
            autoFocus
          />
          <Box sx={{ mb: 2 }}>
            <InputLabel htmlFor={`body-input`}>
              Email Body{" "}
              <Typography color="error" component="span">
                *
              </Typography>
            </InputLabel>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              value={emailBody || ""}
              id="fancy-text-tool-area"
              name="description"
              init={{
                height: 200,
                entity_encoding: "raw",
                license_key: "gpl",
                plugins: [
                  "autolink",
                  "lists",
                  "charmap",
                  "preview",
                  "searchreplace",
                  "fullscreen",
                  "media",
                  "table",
                  "code",
                  "emoticons",
                ],
                toolbar:
                  "undo redo | " +
                  "formatselect | " +
                  "bold italic underline strikethrough | " +
                  "alignleft aligncenter alignright | " +
                  "bullist numlist | " +
                  "emoticons | " +
                  "code removeformat",
                emoticons_append: {
                  custom_mind_explode: {
                    keywords: ["brain", "mind", "explode", "blown"],
                    char: "🤯",
                  },
                },
                branding: false,
                statusbar: false,
                menubar: false,
                content_style: "p { margin: 0}",
              }}
              onEditorChange={(newValue, editor) => {
                setEmailBody(newValue);
              }}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "16px 24px" }}>
          <Button onClick={handleCloseEmailModal} variant="outlined">
            Cancel
          </Button>
          <Button onClick={()=>handleSendEmail(emailSubject,emailBody)} variant="contained" color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>)
}

export default EmailDialog